import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { ElMessage, messageTypes } from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import * as ElIconModules from '@element-plus/icons-vue'
import moment from 'moment'
import axios from 'axios'
import echarts from 'echarts'
import myCharts from './js/myEcharts.js'
import store from './store/index.js'
import "./assets/common.css"
// import dayjs from 'dayjs'
// Vue.use(dayjs)

const app = createApp(App)
app.config.globalProperties.$echarts = echarts
// 全局引用components的组件
const requireComponent = require.context('./components', false, /\.vue$/)
requireComponent.keys().forEach(fileName => {
    const config = requireComponent(fileName)
    app.component(config.default.name, config.default || config) // 动态注册
})
//注意use要在mount之前
app.use(store).use(myCharts).use(router).use(ElementPlus, { locale }).mount('#app')
// app.use(dayjs)
app.config.globalProperties.$moment = moment

//定义一个新的Message方法返回ElMessage
const Message = options => {
  options.duration = 2000
  options.offset = 60
  options.showClose = true 
  return ElMessage(options) 
}
//重写一遍success等方法，简化操作
messageTypes.forEach(type => {
  Message[type] = msg => {
    let options = {}
    if (typeof msg === 'string') {
      options = {
        message: msg,
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将新的Message挂载到this上
app.config.globalProperties.$ElMessage = Message

for (let iconName in ElIconModules) {
  app.component(iconName, ElIconModules[iconName]);
}

axios.defaults.baseURL = apiUrl;
app.config.globalProperties.$axios = axios
axios.interceptors.request.use(
  config => {
      // 在发送请求之前先做点事
      // if (config.url.indexOf('http:') != 0) {
      //     if (config.url.indexOf('/') != 0) {
      //       config.url = '/' + config.url
      //     }
      //     config.url = apiUrl + config.url;
      // }
      let getToken = sessionStorage.getItem('token')
      if (getToken) {
        config.params = {
          ...config.params,
          Token: getToken
        }
      }
      return config
  },
  error => {
      // do something with request error
      return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code === 403) {
    router.push({'path': '/'})
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

//获取字典中的配置
// axios.get('web/Dict/GetDicts', {
//   params: {
//       'page': 1,
//       'limit': 10000
//   }
// }).then(res => {
//   if (res.data.code === 200) {
//     app.config.globalProperties.$basedict={}
//       for (const key in res.data.data) {
//         app.config.globalProperties.$basedict[res.data.data[key].key] = JSON.parse(res.data.data[key].value)
//       }
//   }
// })