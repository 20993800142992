/**
 * 各种画echarts图表的方法都封装在这里
 */

import echarts from 'echarts'
const install = function (Vue) {
  Object.defineProperties(Vue.config.globalProperties, {
    $chart: {
      get() {
        return {
          //画一条简单的线
          line1: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              xAxis: {
                type: 'category',
                data: data.name
              },
              yAxis: {
                type: 'value',
                scale: true,
              },
              series: [{
                data: data.value,
                type: 'line',
                smooth: true
              }],
            }
            this.chart.setOption(optionData)
          },
          //堆叠柱状图
          stackBar: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                x: 'center',
                y: 'bottom',
                textStyle: {
                  color: '#333333',
                  fontSize: 12
                }
              },
              tooltip: {
                trigger: 'axis',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                  type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                }
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true
                  }
                }
              },
              legend: {
                data: data.legendNameArray,
                bottom: 0,
                show:false,
              },
              grid: {
                top: '30px',
                left: '3%',
                right: '6%',
                bottom: '30px',
                containLabel: true
              },
              xAxis: [{
                type: 'category',
                data: data.nameArray
              }],
              yAxis: [{
                name: data.unit,
                type: 'value'
              }],
              series: data.valueArray
            };
            this.chart.setOption(optionData)
          },
          // 饼状图
          pie1: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b} : {c}' + data.unit + ' ({d}%)'
              },
              series: [{
                // name: data.name,
                name: '面积',
                type: 'pie',
                radius: '50%',
                center: ['50%', '50%'],
                data: data.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                itemStyle: {
                  normal: {
                    color: function (params) {
                      return data.color[params.dataIndex]
                    }
                  }
                }
              }]
            }
            this.chart.setOption(optionData)
          },
          sha: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const option = {
              title: {
                text: '',
                subtext: ''
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              legend: {
                data: data.unit
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
              },
              yAxis: {
                name: data.unit,
                type: 'category',
                data: data.name
              },
              series: [{
                name: data.seriesname,
                type: 'bar',
                data: data.value
              }]
            }
            this.chart.setOption(option)
          },
          // 雷达图
          radar: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const option = {
              title: {
                text: ''
              },
              tooltip: {
                trigger: 'axis',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              legend: {
                left: 'center',
                data: []
              },
              radar: [{
                indicator: data.name,
                radius: 80,
                center: ['50%', '50%'],
              }],
              series: [{
                type: 'radar',
                tooltip: {
                  // trigger: 'item'
                },
                textStyle: {},
                data: [{
                  value: data.value,
                  name: data.name,
                  label: {
                    normal: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  }
                }]
              }]
            }
            this.chart.setOption(option)
          },
          //仪表盘
          panel: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            var option = {
              tooltip: {
                formatter: '{a} <br/>{b} : {c}',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              toolbox: {
                show: true,
                feature: {
                  // restore: {show: true},
                  saveAsImage: {
                    show: true
                  }
                }
              },
              series: [{
                  name: '压力',
                  type: 'gauge',
                  center: ['50%', '55%'], // 默认全局居中
                  radius: '100%',
                  min: data.min,
                  max: data.max,
                  endAngle: -40,
                  splitNumber: 5,
                  axisLine: { // 坐标轴线
                    lineStyle: { // 属性lineStyle控制线条样式
                      width: 6,
                      color: data.color
                    }
                  },
                  axisTick: { // 坐标轴小标记
                    length: 9, // 属性length控制线长
                    lineStyle: { // 属性lineStyle控制线条样式
                      color: 'auto'
                    }
                  },
                  splitLine: { // 分隔线
                    length: 12, // 属性length控制线长
                    lineStyle: { // 属性lineStyle（详见lineStyle）控制线条样式
                      color: 'auto'
                    }
                  },
                  axisLabel: {
                    color: 'black',
                    distance: 5,
                    fontSize: 12
                  },
                  pointer: {
                    width: 2
                  },
                  title: {
                    offsetCenter: ['0', '-30%'], // x, y，单位px
                  },
                  detail: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontSize: 15,
                    fontWeight: 'bolder',
                    color: 'black'
                  },
                  data: [{
                    value: data.pressure,
                    itemStyle: {
                      color: 'red'
                    },
                    name: '压力指标'
                  }]
                },

              ]
            };
            this.chart.setOption(option)
          },
          //线柱图
          linebar: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            var option = {
              tooltip: {
                confine: true,
                extraCssText: "z-index:99",
                textStyle: {
                  align: 'left'
                },
                formatter: function (param) {
                  return param[0].name + "<br>" + param[0].data + data.unit
                },
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              toolbox: {
                // feature: {
                //   dataView: {show: true, readOnly: false},
                //   magicType: {show: true, type: ['line', 'bar']},
                //   restore: {show: true},
                //   saveAsImage: {show: true}
                // }
              },
              // legend: {
              //   data: ['万亩']
              // },
              xAxis: [{
                type: 'category',
                data: data.name,
                axisPointer: {
                  type: 'shadow'
                }
              }],
              yAxis: [{
                  type: 'value',
                  name: data.unit,
                  // min: 0,
                  // max: 250,
                  // interval: 50,
                  axisLabel: {
                    formatter: '{value}'
                  }
                }

              ],
              series: [{
                  // name: data.unit,
                  type: 'bar',
                  data: data.value
                },
                {
                  // name: data.unit,
                  type: 'line',
                  data: data.value
                }
              ]
            };
            this.chart.setOption(option)
          },
          //清除
          clear: function (id) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
          },
          //暂无数据
          echartsNoData: function (id) {
            let dom = document.getElementById(id);
            dom.innerHTML = "暂无数据";
            // dom.removeAttribute("_echarts_instance_");
          },
          //清除_echarts_instance_
          clearEchartsInstance: function (id) {
            let dom = document.getElementById(id);
            if (dom.getAttribute("_echarts_instance_")) {
              dom.removeAttribute("_echarts_instance_");
            }
          },
        }
      }
    }
  })
}
export default {
  install
}