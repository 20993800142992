<template>
  <div class="leftChildMenu">
    <el-input ref="treeInput" placeholder="输入关键字搜索" v-model="filterText" clearable></el-input>
    <el-scrollbar class="elscrModules">
      <el-tree :data="data" class="flowSchemesTree" node-key="id" :filter-node-method="filterNode"
        :props="props" default-expand-all @node-click="nodeClick" ref="tree">
        <template #default="{ node, data }">
              <el-icon v-if="data.children && data.children.length > 0">
                <FolderOpened />
              </el-icon>
              <el-icon v-else>
                <Document /> 
              </el-icon>
              <span :title="node.label">{{ node.label }}</span>
        </template>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'treeFilter',
  emits: ['nodeClick'],
  props: {
    data:{type: Array, default: []},
    props:{type: Object, default: {children: 'children', label: 'name'}},
  },
  data() {
    return {
      filterText: "",
    };
  },
  methods: {
    // tree绑定搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    nodeClick(data,node,treenode,event){
      this.$emit('nodeClick', data,node,treenode,event)
    }
  },
  watch:{
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style>
.leftChildMenu{width: 200px; height:100%; padding: 5px 5px 5px 0;}
.leftChildMenu .elscrModules{height: 86%; width: 200px; min-width: 200px;}

.module_tree_con{width:100%;line-height:26px;height:26px;position:relative;padding-left:20px;}
.el_tree_icons {position: absolute;left: 0;font-size:14px;}
.el_tree_new_icons {position: absolute;left: 0;font-size:18px;}
.el_tree_text {width: 100%;height: 26px;line-height: 26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap}
.el_tree_text_active {color: #48a2ff}


/*第一个节点不设置padding-left*/
.flowSchemesTree> .el-tree-node{font-size:14px;}
.flowSchemesTree> .el-tree-node:first-child{padding-left:0}
.flowSchemesTree .el-tree-node{padding-left:18px;position:relative}
.flowSchemesTree .el-tree-node .el-tree-node__content{padding-left:0 !important}
.flowSchemesTree .el-tree-node__children{position:relative}

/*文件夹或者文件前面的横线*/
.flowSchemesTree .el-tree-node .custom-tree-node{position:relative;padding-left:10px}
.flowSchemesTree .el-tree-node .custom-tree-node::before,.flowSchemesTree .el-tree-node.is-expanded .custom-tree-node::before{border-bottom:1px solid #ececec;position:absolute;left:0;top:50%;width:7px;height:1px;line-height:1px;content:""}

/*文件夹或者文件前面的竖线*/
.flowSchemesTree > .el-tree-node:first-child::before{border-left:0;}
.flowSchemesTree .el-tree-node::before{position:absolute;left:40px;top:0;width:1px;height: 100%;border-left:1px solid #ececec;content:""}
.flowSchemesTree .el-tree-node > .el-tree-node__children > .el-tree-node:last-child::before,
.flowSchemesTree > .el-tree-node:nth-last-child(2)::before{height: 14px;line-height: 14px;}


/*修改图标*/
.flowSchemesTree .el-tree-node.is-expanded > .el-tree-node__content > .custom-tree-node > .module_tree > .icon-24gl-folderPlus::before,
.flowSchemesTree .el-tree-node.is-expanded > .el-tree-node__content > .custom-tree-node > .children_node > .module_tree > .icon-24gl-folderPlus::before {
  content:"\eabe";
}
.flowSchemesTree .el-tree-node.is-expanded .is-leaf + .custom-tree-node .icon-24gl-folderPlus:before {content: "\eabe";}
.flowSchemesTree .el-tree-node > .el-tree-node__content > .custom-tree-node > .module_tree > .icon-24gl-folderPlus::before,
.flowSchemesTree .el-tree-node > .el-tree-node__content > .custom-tree-node > .children_node > .module_tree > .icon-24gl-folderPlus::before {
  content: "\eabf";
}

/* 展开图标的颜色 */
.flowSchemesTree .el-tree-node__expand-icon {color: #c0c4cc}
.flowSchemesTree .el-tree-node__content .el-tree-node__expand-icon.is-leaf {color: transparent}
/* .flowSchemesTreeNew > .el-tree-node:first-child {padding-left: 18px;}
.flowSchemesTreeNew > .el-tree-node:first-child::before {border-left: 1px solid #ececec;} */
</style>


