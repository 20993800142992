<template>
  <!-- 外层div不能去，影响显示高度计算 -->
  <div>
    <!-- 表格和分页 -->
    <el-table
      ref="comTable"
      :data="tableData"
      row-key="id"
      height="calc(100% - 105px)"
      class="tableAll"
      border
      @row-click="rowClick"
      @cell-click="cellClick">
      <!-- 复选框--默认显示 :showSelectColumn="true/false" -->
      <el-table-column v-if="showSelectColumn" fixed type="selection" width="55"></el-table-column>
      <!-- 索引--默认不显示 -->
      <el-table-column v-if="showIndexColumn" type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column v-for="(item, index) in tablecol" :key="index" :prop="item.prop" :label="item.label" :width="item.width" :fixed="item.fixed" :msgShow="item.msgShow" :formatter="item.formatter">
        <template #default="scope" v-if="item.multiline || item.scopeShow">
          <!-- 多行显示 -->
          <div class="templateClass align" v-if="item.multiline">
            <el-scrollbar style="height: 100%;">
              {{scope.row[item.prop]}}
            </el-scrollbar>
          </div>
          <!-- 操作按钮 -->
          <el-button v-if="item.scopeShow" @click="scopeClick(scope.row.id)" type="primary" size="small">{{item.label}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="pagination"
      v-model:current-page="currpage"
      layout="prev,pager,next,sizes,total,jumper"
      :page-sizes="pageSizes"
      v-model:page-size="pagesize"
      :total="tableCount"
      @current-change="getTable"
      @size-change="getTable"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'tablePagination',
  props: {
    showSelectColumn: { type: Boolean, default: true, required: false }, // 是否显示复选框
    showIndexColumn: { type: Boolean, default: false, required: false }, // 是否显示索引
    tablecol: { type: Array, default: [], required: false }, // 表头数据
    url: { type: String, default: "", required: false }, // 请求url
    params: { type: Object, default: {}, required: false }, // 参数
    pagesize: { type: Number, default: 5, required: false }, // 单页数据量
    pageSizes: { type: Array, default: [5, 10, 15, 20, 30, 40], required: false },
  },
  data() {
    return {
      tableData:[],  // 表格数据
      tableCount:0,  // 分页数据总数
      currpage:1, // 当前页码
    };
  },
  methods: {
    //获取数据
    getTable() {
      let url = this.url
      this.$axios.get(url, {
        params: {
          page: this.currpage,
          limit: this.pagesize,
          ...this.params
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.tableCount = res.data.count
          if(res.data.data){
            this.tableData = res.data.data
          }else{
            this.tableData = res.data.result
          }
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    //选择数据发生变化时
    getRowSelection(){
      return this.$refs.comTable.getSelectionRows()
    },
    // 插入按钮的方法
    scopeClick(id){
      this.$emit('scopeClick', id)
    },
    // 删除后更新数据
    refreshDelete(){
      let deleteNum = this.$refs.comTable.getSelectionRows().length
      let totalPage = Math.ceil((this.tableCount - deleteNum)/this.pagesize)
      this.currpage = this.currpage>totalPage?totalPage:this.currpage
      this.currpage = this.currpage<1?1:this.currpage
      this.getTable()
    },
    // 清空表格
    clearSelection(){
      this.$refs.comTable.clearSelection()
    },
    // 行点击事件，触发父页面事件
    rowClick(row,column){
      this.$emit('rowClick', row)
    },
    // 单元格点击事件，多行显示的单元格点击出弹框
    cellClick(row,column){
      this.tablecol.forEach((item) => {
        if(item.label === column.label){
          if(item.msgShow && item.msgShow === true){
            this.$alert(row[item.prop], column.label, {
              confirmButtonText: '确定',
              draggable: true,
              customClass: 'msgbox'
            }).catch(()=>{})
          } 
        }
      })
    },

  },
  mounted(){
    this.getTable()
  }
};
</script>

<style>
.pagination{
  padding:20px 0;
  bottom:0;
  z-index:999;
}

.templateClass{
  width:100%;
  height:70px;
  overflow:hidden;
}
.align{
  text-align:justify;
  text-justify:inter-ideograph;
  word-break: break-all;
  word-wrap: break-word;
}
.tableAll{ height: 100%;}
.tableAll th.el-table__cell {background: #F5F7FA;font-size: 15px;text-align: center;}
.tableAll.is-scrolling-none th.el-table-fixed-column--left, .tableAll.is-scrolling-none th.el-table-fixed-column--right {background: #F5F7FA; text-align: left;}
.tableAll .el-table__inner-wrapper{height: 100% !important;}


/* 消息弹出框样式 */
.msgbox {
  width: 100%;
  height: auto;
}
@media all and (min-width: 768px) {
  .msgbox {width: 600px; max-height: 300px;}
  .msgbox .el-message-box__content{max-height: 200px;overflow-y: auto;}
}
</style>