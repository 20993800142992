<template>
  <div class="tlf-layout">
    <div class="tlf-top">
      <slot name="top"></slot>
    </div>
    <div class="tlf-bottom">
      <div class="tlf-left">
        <slot name="left"></slot>
      </div>
      <div class="tlf-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.tlf-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tlf-top {
    width: 100%;
  }
  .tlf-bottom{
    height: 0;//防止子元素设置百分比时获取不到父元素的高度
    flex:1;
    width:100%;
    display: flex;
    .tlf-left{
        width:200px;
        height: 100%;
    }
    .tlf-right{
        width: calc(100% - 200px);
        height: 100%;
        padding:20px;
        box-sizing: border-box;
    }
  }
}
</style>