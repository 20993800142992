import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/Login/Login'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../pages/Home/Home'),
    children: [
      {
        path: '/OneMaps',
        name: 'OneMaps',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/OneMaps/PlantingManagementOneMapIndex',
            name: 'OneMapPlantingManagement',
            component: () => import('../pages/OneMaps/PlantingManagementOneMapIndex')
          }, {
            path: '/OneMaps/BasicResourcesOneMapIndex',
            name: 'OneMapBasicResources',
            component: () => import('../pages/OneMaps/BasicResourcesOneMapIndex')
          },
        ]
      },
      {
        path: '/DeviceManage',
        name: 'DeviceManage',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/DeviceManage/trsqy',
            name: 'trsqy',
            component: () => import('../pages/DeviceManage/trsqy')
          },
          {
            path: '/DeviceManage/cqcby',
            name: 'cqcby',
            component: () => import('../pages/DeviceManage/cqcby')
          },
          {
            path: '/DeviceManage/qxgcz',
            name: 'qxgcz',
            component: () => import('../pages/DeviceManage/qxgcz')
          },
          {
            path: '/DeviceManage/gqsxt',
            name: 'gqsxt',
            component: () => import('../pages/DeviceManage/gqsxt')
          }
        ]
      },
      {
        path: '/Manager',
        name: 'Manager',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/Manager/DictConfig',
            name: 'DictConfig',
            component: () => import('../pages/Manager/DictConfig')
          },
          {
            path: '/Manager/OrgConfig',
            name: 'OrgConfig',
            component: () => import('../pages/Manager/OrgConfig')
          },
          {
            path: '/Manager/RegionManager',
            name: 'RegionManager',
            component: () => import('../pages/Manager/RegionManager')
          },
          {
            path: '/Manager/sblbManager',
            name: 'sblbManager',
            component: () => import('../pages/Manager/sblbManager')
          },
          {
            path: '/Manager/sbxxManager',
            name: 'sbxxManager',
            component: () => import('../pages/Manager/sbxxManager')
          },
          {
            path: '/Manager/nsxx',
            name: 'nsxx',
            component: () => import('../pages/Manager/nsxx')
          },
          {
            path: '/Manager/xtjl',
            name: 'xtjl',
            component: () => import('../pages/Manager/xtjl')
          },
          {
            path: '/Manager/zwxx',
            name: 'zwxx',
            component: () => import('../pages/Manager/zwxx')
          },
          {
            path: '/Manager/dwry',
            name: 'dwry',
            component: () => import('../pages/Manager/dwry')
          },
          {
            path: '/Manager/UserManage/UserConfig',
            name: 'UserConfig',
            component: () => import('../pages/Manager/UserManage/UserConfig')
          },
          {
            path: '/Manager/UserManage/SafeConfig',
            name: 'SafeConfig',
            component: () => import('../pages/Manager/UserManage/SafeConfig')
          },
          {
            path: '/Manager/RoleConfig',
            name: 'RoleConfig',
            component: () => import('../pages/Manager/RoleConfig')
          },
          {
            path: '/Manager/ModulesConfig',
            name: 'ModulesConfig',
            component: () => import('../pages/Manager/ModulesConfig')
          },
          {
            path: '/Manager/DailyRecordManage',
            name: 'DailyRecordManage',
            component: () => import('../pages/Manager/DailyRecordManage')
          },
          {
            path: '/Manager/dkgl',
            name: 'dkgl',
            component: () => import('../pages/Manager/dkgl')
          }
        ]
      },
      {
        path: '/ReportCenter',
        name: 'ReportCenter',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/ReportCenter/ntsjfx',
            name: 'ntsjfx',
            component: () => import('../pages/ReportCenter/ntsjfx')
          },
          {
            path: '/ReportCenter/zwsjfx',
            name: 'zwsjfx',
            component: () => import('../pages/ReportCenter/zwsjfx')
          }
        ]
      }
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// }
export default router
